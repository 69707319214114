
































import { computed, defineComponent } from "@vue/composition-api";
import { IOmiRequest } from "@/types";
import { PropType } from "vue";

export default defineComponent({
  props: {
    omiRequest: {
      type: Object as PropType<IOmiRequest>
    }
  },
  setup(props) {
    const logo = computed(() => require("@/assets/img/tng_logo.png"));
    const omi = computed(() => JSON.stringify(props.omiRequest));
    const orderNo = computed(() => props.omiRequest.order_no);
    const amount = computed(() => props.omiRequest.amount);
    const tng_tx_no = computed(() => props.omiRequest.tng_tx_no);

    return { logo, omi, orderNo, amount, tng_tx_no };
  }
});
